<template>
	<!-- TODO: @close should only close modal, but right now it will emit close event
	since this is needed for modal to be supported on top of AddBlockModal.
	Once moodal system has multiple modals support, this should be updated. -->
	<ChooseBlockPlacementModal
		:primary-button-text="$t('builder.addEcommerceBlock.buttonAddPage')"
		:secondary-button-text=" $t('builder.addEcommerceBlock.buttonAddHere')"
		:hide-secondary-button="isSecondaryButtonHidden"
		:title="$t('builder.addEcommerceBlock.title')"
		:subtitle="$t('builder.addEcommerceBlock.subtitle')"
		@close="isSecondaryButtonHidden ? closeModal() : $emit('close')"
		@add-primary="addPage(ECOMMERCE_CAT_ID)"
		@add-secondary="addEcommerceBlock"
	>
		<template #header-image>
			<img src="@/assets/images/store-sample.png">
		</template>
	</ChooseBlockPlacementModal>
</template>

<script>
import {
	mapStateGui,
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useAddBlockModal } from '@/use/useAddBlockModal';

import ChooseBlockPlacementModal from './ChooseBlockPlacementModal.vue';

export default {
	components: { ChooseBlockPlacementModal },
	setup(props, context) {
		const {
			addPage,
			addEcommerceBlock,
			ECOMMERCE_CAT_ID,
		} = useAddBlockModal(context);

		return {
			addPage,
			addEcommerceBlock,
			ECOMMERCE_CAT_ID,
		};
	},
	computed: {
		...mapStateGui(['activeModalSettings']),
		isSecondaryButtonHidden: ({ activeModalSettings }) => activeModalSettings?.hideSecondaryButton
		|| false,
	},
	methods: mapActionsGui({ closeModal: CLOSE_MODAL }),
};
</script>
