import {
	ref,
	computed,
} from '@vue/composition-api';
import { nanoid } from 'nanoid';

import EventLogApi from '@/api/EventLogApi';
import {
	getBlogListBlock,
	getEcwidBlock,
} from '@/components/block/blocks';
import {
	BLOG_LIST_PAGE_TYPE,
	ECOMMERCE_PAGE_TYPE,
} from '@/constants';
import template from '@/data/AddBlockModalTemplate.json';
import i18n from '@/i18n/setup';
import { BUILDER_ROUTE } from '@/router';
import { CLOSE_MODAL } from '@/store/builder/gui';
import { useSiteStyles } from '@/use/useSiteStyles';
import {
	cloneDeep,
	filterObject,
} from '@/utils/object';

export const useAddBlockModal = (context) => {
	const { $store } = context.root;

	const { activeModalSettings } = $store.state.gui;
	const { siteCSSVariables } = useSiteStyles();

	const DEFAULT_PAGE_ID = 'about-page';

	// Custom ecommerce logic
	const showEcommerceChoice = ref(false);
	const isPageWithEcwid = computed(() => $store.getters['onlineStore/isPageWithEcwid']);
	const ECOMMERCE_CAT_ID = 'online-store';

	// Custom blog logic
	const showBlogListChoice = ref(false);
	const BLOG_CAT_ID = 'blog-page';

	// Custom footer logic
	const showFooterGlobalChoice = ref(false);
	const doesFooterExist = computed(() => $store.getters.doesFooterExist);
	const FOOTER_CAT_ID = 'footer-page';

	// Custom slideshow logic
	const SLIDESHOW_CAT_ID = 'slideshow-page';

	const selectedBlockId = ref(null);

	// Sidebar categories
	const categories = computed(() => {
		const categoriesTemporary = {
			[BLOG_CAT_ID]: {
				name: i18n.t('common.blog'),
				hasNewBadge: true,
			},
			[ECOMMERCE_CAT_ID]: {
				name: i18n.t('common.onlineStore'),
				hasNewBadge: true,
			},
			separator: { type: 'separator' },
			[DEFAULT_PAGE_ID]: { name: i18n.t('common.about') },
			'gallery-page': {
				name: 'Gallery',
				hasNewBadge: true,
			},
			'contact-form': { name: i18n.t('common.contactForm') },
			'footer-page': {
				name: i18n.t('common.footer'),
				icon: 'link',
			},
			'headlines-page': { name: i18n.t('common.headlines') },
			'images-page': { name: i18n.t('common.images') },
			'instagram-feed': { name: i18n.t('common.instagramFeed') },
			'map-page': { name: i18n.t('common.map') },
			[SLIDESHOW_CAT_ID]: {
				name: i18n.t('common.slideshow'),
				hasNewBadge: true,
			},
			'social-icons': { name: i18n.t('common.socialIcons') },
			'subscribe-form': { name: i18n.t('common.subscribeForm') },
			zD35qbKzB: {
				name: i18n.t('common.testimonials'),
				hasNewBadge: true,
			},
			'video-page': { name: i18n.t('common.video') },
		};

		if (isPageWithEcwid.value) {
			delete categoriesTemporary[ECOMMERCE_CAT_ID];
		}

		return categoriesTemporary;
	});

	// Current sidebar category
	const currentCategory = ref('');
	const currentCategoryId = computed({
		get: () => {
			if (currentCategory.value) {
				return currentCategory.value;
			}

			if (categories?.value[DEFAULT_PAGE_ID]) {
				return DEFAULT_PAGE_ID;
			}

			return categories.value ? Object.keys(categories.value)[0] : '';
		},
		set: (value) => {
			currentCategory.value = value;
		},
	});

	// Current selected category blocks
	const blocks = computed(() => {
		const nonAddableBlocks = [
			'BlockNavigation',
			'BlockBlogHeader',
		];

		if (!template) {
			return null;
		}

		if (currentCategoryId.value === ECOMMERCE_CAT_ID) {
			return { ecwid: getEcwidBlock() };
		}

		if (currentCategoryId.value === BLOG_CAT_ID) {
			return { blog: getBlogListBlock({ mockCategories: true }) };
		}

		const pageBlocks = template.pages[currentCategoryId.value]?.blocks || [];

		const filteredBlocks = filterObject(
			template.blocks,
			({
				key,
				value,
			}) => pageBlocks.includes(key) && !nonAddableBlocks.includes(value.type),
		);

		return filteredBlocks;
	});

	// Current selected category components
	const components = computed(() => {
		if (currentCategoryId.value === ECOMMERCE_CAT_ID || currentCategoryId.value === BLOG_CAT_ID) {
			return {};
		}

		return template.components;
	});

	// Element addition
	async function scrollToNextBlock(blockReference) {
		await context.root.$nextTick;
		blockReference.nextSibling.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}

	// Add stuff
	function addPage(type) {
		if (type === BLOG_CAT_ID) {
			$store.dispatch('pages/addPage', { type: BLOG_LIST_PAGE_TYPE });
			$store.dispatch(`gui/${CLOSE_MODAL}`);

			window.hj('identify', $store.state.user.user.id, { 'builder.blog.add_blog_page': true });
		}

		if (type === ECOMMERCE_CAT_ID) {
			EventLogApi.logEvent({ eventName: 'template.eshop-block-added' });
			$store.dispatch('pages/addPage', { type: ECOMMERCE_PAGE_TYPE });
			$store.dispatch(`gui/${CLOSE_MODAL}`);
			if (this.$route.name !== BUILDER_ROUTE) {
				this.$router.push({ name: BUILDER_ROUTE });
			}
		}
	}

	function addNewBlock(blockId, blockData, addToPage, slot) {
		const {
			previousBlockId,
			previousBlockRef,
		} = activeModalSettings;

		// If no blockId given, add a default empty block
		if (!blockId && !addToPage) {
			$store.dispatch('addBlock', { previousBlockId });
			$store.dispatch(`gui/${CLOSE_MODAL}`);
			scrollToNextBlock(previousBlockRef);

			return;
		}

		// Show popup before adding blog/ecommerce
		if (currentCategoryId.value === ECOMMERCE_CAT_ID && !addToPage) {
			showEcommerceChoice.value = true;

			return;
		}

		// Show popup before adding blog
		if (currentCategoryId.value === BLOG_CAT_ID && !addToPage) {
			showBlogListChoice.value = true;

			return;
		}

		// Show popup before adding footer
		if (currentCategoryId.value === FOOTER_CAT_ID
				&& !doesFooterExist.value
				&& !showFooterGlobalChoice.value) {
			showFooterGlobalChoice.value = true;

			return;
		}

		// If the current element is bloglist, handle it specifically.
		if (blockData.type === 'BlockBlogList') {
			$store.dispatch('addBlock', {
				previousBlockId,
				block: blockData,
			});
			$store.dispatch(`gui/${CLOSE_MODAL}`);
			scrollToNextBlock(previousBlockRef);

			return;
		}

		if (blockData.type === 'BlockSlideshow') {
			const slideshowBlock = cloneDeep(template.blocks[blockId]);
			const newSlidesArray = cloneDeep(template.blocks[blockId].slides);

			const [slideComponents, slides] = slideshowBlock.slides
				.reduce(([newComponents, newSlides], slide, index) => {
					const newSlideId = nanoid();
					const slideBlockId = slide.blockId;
					const slideBlock = cloneDeep(template.blocks[slideBlockId]);

					slideBlock.components = [];
					slideBlock.zindexes = [];

					const newSlideComponents = template.blocks[slideBlockId].components
						.reduce((newSlideComponentsAccumulator, componentId) => {
							const slideComponentId = nanoid();

							slideBlock.components.push(slideComponentId);
							slideBlock.zindexes.push(slideComponentId);

							return {
								...newSlideComponentsAccumulator,
								[slideComponentId]: template.components[componentId],
							};
						}, {});

					newSlidesArray[index] = {
						blockId: newSlideId,
						name: `${i18n.t('common.slide')} ${index + 1}`,
					};

					return [
						{
							...newComponents,
							...newSlideComponents,
						},
						{
							...newSlides,
							[newSlideId]: slideBlock,
						},
					];
				}, [
					[],
					{},
				]);

			slideshowBlock.slides = newSlidesArray;
			const slideshowId = nanoid();

			$store.dispatch('addBlockSlideshow', {
				previousBlockId,
				blockId: slideshowId,
				block: slideshowBlock,
				slides,
				components: slideComponents,
				addToPage: true,
			});

			EventLogApi.logEvent({ eventName: 'builder.slideshow.add' });

			$store.dispatch(`gui/${CLOSE_MODAL}`);
			scrollToNextBlock(previousBlockRef);

			return;
		}

		if (blockData.type === 'BlockEcwidStore') {
			$store.dispatch('addBlock', {
				previousBlockId,
				block: blockData,
			});
			$store.dispatch(`gui/${CLOSE_MODAL}`);
			scrollToNextBlock(previousBlockRef);

			return;
		}

		// Prepare blocks and components objects with new ids
		const {
			blocks: templateBlocks,
			components: templateComponents,
		} = template;
		const block = cloneDeep(templateBlocks[blockId]);
		const blockComponents = {};
		const blockComponentsIds = [];

		templateBlocks[blockId].components.forEach((componentId) => {
			const id = nanoid();

			blockComponents[id] = cloneDeep(templateComponents[componentId]);
			blockComponentsIds.push(id);
		});
		block.components = blockComponentsIds;
		block.zindexes = [...blockComponentsIds];

		$store.dispatch('addBlock', {
			previousBlockId,
			block: {
				...block,
				slot,
			},
			addToPage: slot !== 'footer',
			components: blockComponents,
		});
		$store.dispatch(`gui/${CLOSE_MODAL}`);
		scrollToNextBlock(previousBlockRef);
		EventLogApi.logEvent({ eventName: 'user.addBlockFromLibrary' });
	}

	function addBlogList() {
		$store.dispatch('blog/addPredefinedPages');
		addNewBlock(
			'blockBlogList',
			getBlogListBlock({ mockCategories: false }),
			true,
		);

		EventLogApi.logEvent({ eventName: 'builder.blog.insert_blog_list' });
		window.hj('identify', $store.state.user.user.id, { 'builder.blog.insert_blog_list': true });
	}

	function addEcommerceBlock() {
		addNewBlock(
			'blockEcwidStore',
			getEcwidBlock(),
			true,
		);

		EventLogApi.logEvent({ eventName: 'template.eshop-block-added' });
	}

	return {
		template,
		categories,
		components,
		doesFooterExist,
		selectedBlockId,
		currentCategoryId,
		addNewBlock,
		blocks,
		addEcommerceBlock,
		showEcommerceChoice,
		showBlogListChoice,
		showFooterGlobalChoice,
		addPage,
		addBlogList,
		BLOG_CAT_ID,
		ECOMMERCE_CAT_ID,
		siteCSSVariables,
	};
};
